import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { testObj, testResult, testName, isDarkState } from "../atom";

const Wrapper = styled(motion.div)`
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
`;
const Container = styled(motion.div)`
  min-width: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: center;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem 0px;
`;
const AskDiv = styled(motion.div)`
  background: url("img/bg${(props) => props.id}.png");
  background-size: 100% 100%;
  flex-shrink: 0;
  width: 90%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Dragdiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
const DragZone = styled(motion.div)`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ScoreGrid = styled(motion.div)`
  display: grid;
  width: 83%;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  margin-top: 0.8rem;
`;
const ScoreBox = styled(motion.div)`
  background-color: transparent;
  place-self: center;
  width: 8.2rem;
  height: 7.8rem;
  border-radius: 20px;
  &:nth-child(2) {
    margin-bottom: 0.6rem;
  }
  &:nth-child(1) {
    width: 7.7rem;
  }
  &:nth-child(3) {
    width: 7.3rem;
  }
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1); */
  border: none;
`;
const Svg = styled(motion.svg)`
  border: none;
`;
const AskContent = styled.p`
  margin-top: 1rem;
  width: 80%;
  text-align: center;
  line-height: 1.2;
  font-family: "Noto Sans KR", sans-serif;
  color: rgba(0, 0, 0);
  font-weight: 400;
  position: relative;
`;
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  height: 4rem;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  border-bottom: 1.5px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  div i,
  div p {
    &:hover {
      cursor: pointer;
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,
    rgba(255, 255, 255, 1) 1.5px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(1px);
`;
const ScoreBoxVariants = {
  hover: {
    rotateZ: -20,
    transition: { duration: 0.3, type: "spring", damping: 2 },
  },
  tap: {
    rotateZ: 20,
    transition: { duration: 0.2, type: "spring", damping: 2 },
  },
  initial: {
    opacity: 0,
  },
  end: {
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.3,
    },
  },
};
const DragZoneVariants = {
  initial: { opacity: 0 },
  end: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
};

function Page1({ id }) {
  const [btnView, setBtnView] = useState(false);
  const scrollRef = useRef(0);

  const oneref = useRef(null);
  const tworef = useRef(null);
  const threeref = useRef(null);

  const cononeref = useRef(null);
  const contworef = useRef(null);
  const conthreeref = useRef(null);

  function getconRef(id) {
    if (id === 1) {
      return cononeref;
    } else if (id === 2) {
      return contworef;
    } else if (id === 3) {
      return conthreeref;
    }
  }

  function getRef(id) {
    if (id === 1) {
      return oneref;
    } else if (id === 2) {
      return tworef;
    } else if (id === 3) {
      return threeref;
    }
  }

  const handleScroll = () => {
    if (window.scrollY > scrollRef.current) {
      setBtnView(true);
    } else {
      setBtnView(false);
    }
    scrollRef.current = window.scrollY;
  };

  const onContent1Click = () => {
    cononeref.current?.scrollIntoView({ top: 0, behavior: "smooth" });
  };
  const onContent2Click = () => {
    contworef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
  };
  const onContent3Click = () => {
    conthreeref.current?.scrollIntoView({ top: 0, behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ref = useRef(null);
  const navigate = useNavigate();

  const { scrollYProgress: progressY } = useScroll();
  const { scrollY } = useScroll({ target: ref });
  console.log(scrollY);

  const [isDropped, setIsDropped] = useState([]);
  const setTest = useSetRecoilState(testName);

  const [dragimg, setDragimg] = useState("");

  useEffect(() => {
    setIsDropped([
      {
        id: 1,
        checked: false,
        scorenum: [
          {
            num: 1,
            dropped: false,
          },
          {
            num: 2,
            dropped: false,
          },
          {
            num: 3,
            dropped: false,
          },
          {
            num: 4,
            dropped: false,
          },
        ],
      },
      {
        id: 2,
        checked: false,
        scorenum: [
          {
            num: 1,
            dropped: false,
          },
          {
            num: 2,
            dropped: false,
          },
          {
            num: 3,
            dropped: false,
          },
          {
            num: 4,
            dropped: false,
          },
        ],
      },
      {
        id: 3,
        checked: false,
        scorenum: [
          {
            num: 1,
            dropped: false,
          },
          {
            num: 2,
            dropped: false,
          },
          {
            num: 3,
            dropped: false,
          },
          {
            num: 4,
            dropped: false,
          },
        ],
      },
      setTest(parseInt(id)),
    ]);
    const timer = setInterval(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [quest, setQuest] = useRecoilState(testObj);

  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);
  const [score3, setScore3] = useState(0);

  const getObjectById = (id) => {
    return quest.find((item) => item.testid === parseInt(id)) || {};
  };
  const testObject = getObjectById(id);
  const { questions } = testObject;

  const scaleX = useSpring(progressY, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const DragandDrop = (x, y, itemid, num, score) => {
    const { left, width } = getRef(itemid)?.current.getBoundingClientRect();

    if (num === 1) {
      setDragimg("img/3dsim1.png");
    } else if (num === 2) {
      setDragimg("img/3dsim2.png");
    } else if (num === 3) {
      setDragimg("img/3dsim3.png");
    } else if (num === 4) {
      setDragimg("img/3dsim4.png");
    }

    if (itemid === 1) {
      setScore1(score);
    } else if (itemid === 2) {
      setScore2(score);
    } else if (itemid === 3) {
      setScore3(score);
    }
    if (itemid === 1) {
      console.log(left, width);
      if (x >= left && x <= left + width) {
        setIsDropped((prevState) =>
          prevState.map((obj) => {
            if (obj?.id === itemid) {
              return {
                ...obj,
                checked: true,
                scorenum: obj?.scorenum?.map((scoreobj) => {
                  if (scoreobj?.num === num) {
                    return {
                      ...scoreobj,
                      dropped: true,
                    };
                  }
                  return scoreobj;
                }),
              };
            }
            return obj;
          })
        );
        setTimeout(() => {
          onContent2Click();
        }, 1000);
      }
    } else if (itemid === 2) {
      console.log(left, width);
      if (x >= left && x <= left + width) {
        setIsDropped((prevState) =>
          prevState.map((obj) => {
            if (obj?.id === itemid) {
              return {
                ...obj,
                checked: true,
                scorenum: obj?.scorenum?.map((scoreobj) => {
                  if (scoreobj?.num === num) {
                    return {
                      ...scoreobj,
                      dropped: true,
                    };
                  }
                  return scoreobj;
                }),
              };
            }
            return obj;
          })
        );
        setTimeout(() => {
          onContent3Click();
        }, 1000);
      }
    } else if (itemid === 3) {
      console.log(left, width);
      if (x >= left && x <= left + width) {
        setIsDropped((prevState) =>
          prevState.map((obj) => {
            if (obj?.id === itemid) {
              return {
                ...obj,
                checked: true,
                scorenum: obj?.scorenum?.map((scoreobj) => {
                  if (scoreobj?.num === num) {
                    return {
                      ...scoreobj,
                      dropped: true,
                    };
                  }
                  return scoreobj;
                }),
              };
            }
            return obj;
          })
        );
      }
    }
  };
  const nextPage = () => {
    scrollToTop();

    navigate("/page2", {
      state: {
        id: id,
        score1,
        score2,
        score3,
      },
    });
  };

  return (
    <Wrapper id={id} ref={ref}>
      <HeaderDiv>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            onClick={() => {
              window.location.reload();
            }}
          >
            <i style={{ marginRight: 10 }} class="fa-solid fa-arrow-left"></i>
            Back
          </p>
        </div>
        <svg id="totalprogress" width="60" height="60" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="30" pathLength="1" />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            id="indicator"
            style={{ pathLength: progressY }}
          />
        </svg>
      </HeaderDiv>
      {questions.slice(0, 3).map((item) => (
        <Container
          ref={getconRef(item.id)}
          style={{
            paddingBottom: item.id === 3 ? 650 : 0,
          }}
        >
          <AskDiv id={id} layoutId={item.id === 1 ? id + "" : null}>
            <AskContent>{item.question}</AskContent>
          </AskDiv>
          <Dragdiv>
            <DragZone
              ref={getRef(item.id)}
              variants={DragZoneVariants}
              initial="initial"
              animate="end"
              style={{
                background: isDropped[item.id - 1]?.checked
                  ? `url(${dragimg}) center/cover no-repeat`
                  : "rgba(255, 255, 255, 1)",

                border: isDropped[item.id - 1]?.checked
                  ? "none"
                  : "4px dotted rgba(0,0,0,1)",
              }}
            >
              {isDropped[item.id - 1]?.checked ? null : <p>Drag</p>}
            </DragZone>
          </Dragdiv>
          <ScoreGrid>
            <ScoreBox
              onPanEnd={(e, info) => {
                console.log(item.id);
                console.log(info.point.x, info.point.y);
                DragandDrop(info.point.x, info.point.y, item.id, 1, 2.5);
              }}
              style={{
                visibility: isDropped[item.id - 1]?.scorenum[0]?.dropped
                  ? "hidden"
                  : "block",
              }}
              variants={ScoreBoxVariants}
              drag
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              animate="end"
              dragSnapToOrigin={true}
            >
              <Svg
                version="1.1"
                xmlns="http://www.w3.org/2000/Svg"
                x="0px"
                y="0px"
                width="100%"
                viewBox="0 0 1024 928"
              >
                <path
                  fill="rgba(255, 201, 0)"
                  opacity="1.000000"
                  stroke="none"
                  d="
M145.231750,379.505768 
   C149.766205,351.052368 157.108826,323.756714 167.169525,297.267059 
   C180.108780,263.198212 197.021576,231.157059 218.096054,201.361740 
   C244.968140,163.369736 277.069183,130.474869 314.519257,102.874619 
   C350.839203,76.107262 390.408844,55.626324 433.310699,41.434879 
   C457.888611,33.304790 482.951660,27.381695 508.480835,23.663353 
   C540.020081,19.069632 571.698364,17.641937 603.603088,20.482126 
   C634.723328,23.252487 665.324280,28.491081 695.056274,37.903198 
   C798.562073,70.669502 879.686340,132.604248 936.986511,225.129440 
   C963.287598,267.598907 981.015686,313.535950 991.242737,362.376404 
   C998.438599,396.740906 1001.674927,431.520294 1000.040649,466.590271 
   C998.831848,492.528229 995.406494,518.219360 989.677979,543.643555 
   C978.591858,592.846436 959.888733,638.880920 932.638306,681.231262 
   C888.416199,749.957642 829.596985,802.458984 755.691162,837.747742 
   C709.867981,859.627502 661.658569,873.250244 611.116211,877.859863 
   C566.080566,881.967285 521.325989,879.708374 477.032501,869.719482 
   C402.100403,852.821106 336.231873,818.557861 279.423431,766.990417 
   C238.869629,730.178101 206.794418,686.888123 182.820724,637.651123 
   C169.742447,610.790955 159.507156,582.789551 152.318054,553.841797 
   C141.946106,512.077759 137.760681,469.565277 139.941452,426.525391 
   C140.733139,410.900513 142.717087,395.383484 145.231750,379.505768 
M895.259888,222.225266 
   C858.108093,169.253525 810.781555,127.886971 753.502258,97.846275 
   C721.896545,81.270378 688.555176,69.399460 653.785950,61.672039 
   C634.282104,57.337326 614.468201,54.963959 594.462891,53.752323 
   C573.439819,52.479053 552.456116,52.665913 531.566467,54.545071 
   C498.592041,57.511322 466.545746,65.120697 435.404327,76.408974 
   C385.287964,94.575363 340.353424,121.373161 301.152466,157.559540 
   C260.715271,194.887161 229.291306,238.733536 206.936707,289.042389 
   C190.255539,326.583191 179.503662,365.720947 175.108505,406.606720 
   C171.656097,438.722778 171.916519,470.768707 176.388199,502.757629 
   C182.714005,548.010437 196.148575,591.072205 217.251511,631.597473 
   C254.864746,703.828613 309.443237,759.509460 381.062378,798.439941 
   C431.276489,825.735168 484.995483,841.456116 541.854309,845.394531 
   C588.527405,848.627441 634.644287,844.068054 679.741577,830.755310 
   C729.531372,816.057190 774.997986,793.000671 815.671448,760.754395 
   C878.635376,710.835754 922.165649,647.371460 947.252319,571.087219 
   C957.706238,539.298462 963.692017,506.768982 965.993591,473.394684 
   C968.769836,433.136627 964.872437,393.514374 955.314636,354.499664 
   C943.668762,306.961792 923.757019,262.993866 895.259888,222.225266 
z"
                />
                <path
                  fill="rgba(255, 201, 0)"
                  opacity="1.000000"
                  stroke="none"
                  d="
M445.918335,629.070923 
   C475.457977,653.631287 507.077393,674.086487 542.832642,687.662415 
   C576.838501,700.574097 611.966614,705.525818 648.222107,702.225342 
   C696.500854,697.830444 739.549072,679.179016 780.049133,653.692871 
   C813.183594,632.841797 843.091003,607.922668 871.248779,580.826965 
   C873.289307,578.863525 875.275635,576.824524 877.465027,575.038757 
   C883.911743,569.780701 891.911621,570.224121 897.212952,576.008728 
   C902.528748,581.809021 902.391235,589.815186 896.642639,595.803406 
   C885.436951,607.475952 873.261475,618.124084 861.042114,628.694153 
   C835.539490,650.754395 808.469421,670.641235 779.145752,687.422485 
   C743.540161,707.798706 705.895508,722.613098 665.198181,728.596680 
   C628.873779,733.937317 592.911560,731.965271 557.352051,721.951599 
   C508.264374,708.128235 465.991150,682.308960 427.229126,649.961182 
   C400.013336,627.248901 375.777374,601.610962 352.831268,574.684387 
   C349.139038,570.351624 345.628998,565.979065 345.251160,559.607239 
   C339.041840,561.293884 333.528503,563.060669 327.895782,564.272644 
   C308.600403,568.424805 289.497070,566.962708 270.613831,561.699402 
   C265.648132,560.315308 260.699707,558.776855 255.902405,556.902222 
   C248.166611,553.879395 244.783737,546.021240 247.655594,538.497131 
   C250.387955,531.338501 258.104065,527.939575 265.782898,530.759216 
   C277.590729,535.095093 289.743073,538.117737 302.276062,538.600281 
   C328.404694,539.606079 349.279327,528.491028 366.412964,509.440979 
   C376.979797,497.692291 384.804749,484.153839 391.936218,470.169006 
   C392.465851,469.130341 392.922089,468.053833 393.462372,467.021057 
   C397.205505,459.866180 405.238190,456.698120 412.002533,459.695618 
   C419.196777,462.883667 422.451477,470.982849 419.000580,478.584076 
   C411.743988,494.568024 402.766113,509.542969 391.615662,523.160767 
   C385.899323,530.142029 379.688843,536.614807 372.626465,542.270020 
   C370.715057,543.800537 368.588165,545.247131 367.352936,548.037048 
   C391.038116,577.192444 416.792053,604.509216 445.918335,629.070923 
z"
                />
                <path
                  fill="rgba(255, 201, 0)"
                  opacity="1.000000"
                  stroke="none"
                  d="
M790.051514,313.710815 
   C797.677063,319.078186 799.296814,326.114502 797.726501,334.588684 
   C795.933960,344.262177 792.791565,353.563873 790.434631,363.078247 
   C788.229126,371.981537 779.525940,376.998596 770.802063,375.114990 
   C762.080566,373.231873 755.978149,364.128540 758.087585,354.918854 
   C760.569580,344.082794 763.835327,333.450073 766.146851,322.534698 
   C768.175415,312.955353 780.765076,308.355133 790.051514,313.710815 
z"
                />
                <path
                  fill="rgba(255, 201, 0)"
                  opacity="1.000000"
                  stroke="none"
                  d="
M666.315125,359.215424 
   C663.806458,365.472748 661.530151,371.355682 655.008972,374.188324 
   C648.837219,376.869263 643.197632,375.929504 638.162842,371.881744 
   C632.965942,367.703613 630.666748,362.113281 632.287170,355.468262 
   C633.068970,352.262512 634.517090,349.213104 635.735657,346.121368 
   C638.109436,340.098602 640.797546,334.231415 642.030701,327.766449 
   C643.967224,317.613495 651.813049,312.864227 662.634033,314.812561 
   C670.569397,316.241333 676.047791,324.406708 674.864441,333.362427 
   C673.673767,342.373718 670.085144,350.635132 666.315125,359.215424 
z"
                />
              </Svg>
            </ScoreBox>
            <ScoreBox
              onPanEnd={(e, info) => {
                console.log(item.id);
                DragandDrop(info.point.x, info.point.y, item.id, 2, 5);
              }}
              style={{
                visibility: isDropped[item.id - 1]?.scorenum[1]?.dropped
                  ? "hidden"
                  : "block",
              }}
              variants={ScoreBoxVariants}
              drag
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              animate="end"
              dragSnapToOrigin={true}
            >
              <Svg
                version="1.1"
                xmlns="http://www.w3.org/2000/Svg"
                x="0px"
                y="0px"
                width="100%"
                viewBox="0 0 1000 1000"
              >
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M586.000000,1001.000000 
	C390.666656,1001.000000 195.833328,1001.000000 1.000000,1001.000000 
	C1.000000,667.666687 1.000000,334.333344 1.000000,1.000000 
	C334.333344,1.000000 667.666687,1.000000 1001.000000,1.000000 
	C1001.000000,334.333344 1001.000000,667.666687 1001.000000,1001.000000 
	C862.833313,1001.000000 724.666687,1001.000000 586.000000,1001.000000 
M195.533234,757.038269 
	C198.433319,760.593689 201.089752,764.385681 204.273483,767.665771 
	C223.145676,787.109741 242.431381,806.070251 264.472076,822.059937 
	C295.419403,844.511169 328.758789,862.495605 364.567810,875.699585 
	C399.518280,888.586853 435.794250,896.057617 472.910156,898.831909 
	C486.697754,899.862488 500.651337,899.519226 514.484680,898.863037 
	C529.714050,898.140503 544.970581,896.907776 560.069397,894.835205 
	C573.710022,892.962708 587.256897,890.088501 600.661560,886.887390 
	C623.382385,881.461548 645.350281,873.576172 666.489258,863.702393 
	C688.261597,853.532715 709.611633,842.412720 728.924194,827.941406 
	C742.519287,817.754333 756.373718,807.742798 768.864868,796.292114 
	C789.185242,777.664612 807.758606,757.349609 823.884094,734.794189 
	C845.747803,704.212463 863.247803,671.427551 875.983643,636.111267 
	C884.971008,611.189575 892.072449,585.730896 894.963989,559.312012 
	C896.962769,541.050293 898.548706,522.735779 899.880493,504.412964 
	C900.307800,498.534668 899.472961,492.542694 898.972656,486.623230 
	C897.729614,471.915680 896.493958,457.204865 894.983826,442.523254 
	C892.949341,422.744354 887.848816,403.607422 882.191284,384.620392 
	C873.121826,354.182800 859.966797,325.564026 844.091125,298.080231 
	C830.732605,274.954224 815.205811,253.365005 797.043884,233.957550 
	C782.825317,218.763885 767.159790,204.844055 751.447083,191.150131 
	C733.619263,175.612930 713.720764,162.820419 692.942505,151.592346 
	C667.512512,137.850586 641.010925,126.624809 613.168152,118.482094 
	C585.383240,110.356285 557.072632,105.371498 528.313477,103.180031 
	C514.694092,102.142227 500.905487,102.482285 487.238617,103.138107 
	C472.176819,103.860855 457.142365,105.435265 442.140259,107.050072 
	C422.388336,109.176170 403.233856,114.189842 384.273010,119.955132 
	C354.088135,129.133209 325.548492,141.882950 298.242249,157.791534 
	C275.146729,171.246994 253.630508,186.778290 234.071564,204.761139 
	C213.552811,223.626450 194.632019,244.045639 178.322525,266.917816 
	C156.200043,297.942047 138.483688,331.137512 125.619034,366.918396 
	C116.766380,391.540558 109.870392,416.723480 107.010857,442.823029 
	C105.100296,460.261139 103.401520,477.731842 102.131737,495.225952 
	C101.656883,501.768005 102.548637,508.426331 103.024620,515.016663 
	C103.935791,527.632263 104.413887,540.317444 106.142181,552.825867 
	C108.253052,568.103149 110.692558,583.417908 114.365974,598.378601 
	C121.408516,627.060852 131.839157,654.636597 145.125931,681.015625 
	C151.097122,692.870544 157.710968,704.500061 165.095078,715.520325 
	C174.506866,729.566895 184.994049,742.892883 195.533234,757.038269 
z"
                />
                <path
                  fill="#FFC900"
                  opacity="1.000000"
                  stroke="none"
                  d="
M195.275452,756.783875 
	C184.994049,742.892883 174.506866,729.566895 165.095078,715.520325 
	C157.710968,704.500061 151.097122,692.870544 145.125931,681.015625 
	C131.839157,654.636597 121.408516,627.060852 114.365974,598.378601 
	C110.692558,583.417908 108.253052,568.103149 106.142181,552.825867 
	C104.413887,540.317444 103.935791,527.632263 103.024620,515.016663 
	C102.548637,508.426331 101.656883,501.768005 102.131737,495.225952 
	C103.401520,477.731842 105.100296,460.261139 107.010857,442.823029 
	C109.870392,416.723480 116.766380,391.540558 125.619034,366.918396 
	C138.483688,331.137512 156.200043,297.942047 178.322525,266.917816 
	C194.632019,244.045639 213.552811,223.626450 234.071564,204.761139 
	C253.630508,186.778290 275.146729,171.246994 298.242249,157.791534 
	C325.548492,141.882950 354.088135,129.133209 384.273010,119.955132 
	C403.233856,114.189842 422.388336,109.176170 442.140259,107.050072 
	C457.142365,105.435265 472.176819,103.860855 487.238617,103.138107 
	C500.905487,102.482285 514.694092,102.142227 528.313477,103.180031 
	C557.072632,105.371498 585.383240,110.356285 613.168152,118.482094 
	C641.010925,126.624809 667.512512,137.850586 692.942505,151.592346 
	C713.720764,162.820419 733.619263,175.612930 751.447083,191.150131 
	C767.159790,204.844055 782.825317,218.763885 797.043884,233.957550 
	C815.205811,253.365005 830.732605,274.954224 844.091125,298.080231 
	C859.966797,325.564026 873.121826,354.182800 882.191284,384.620392 
	C887.848816,403.607422 892.949341,422.744354 894.983826,442.523254 
	C896.493958,457.204865 897.729614,471.915680 898.972656,486.623230 
	C899.472961,492.542694 900.307800,498.534668 899.880493,504.412964 
	C898.548706,522.735779 896.962769,541.050293 894.963989,559.312012 
	C892.072449,585.730896 884.971008,611.189575 875.983643,636.111267 
	C863.247803,671.427551 845.747803,704.212463 823.884094,734.794189 
	C807.758606,757.349609 789.185242,777.664612 768.864868,796.292114 
	C756.373718,807.742798 742.519287,817.754333 728.924194,827.941406 
	C709.611633,842.412720 688.261597,853.532715 666.489258,863.702393 
	C645.350281,873.576172 623.382385,881.461548 600.661560,886.887390 
	C587.256897,890.088501 573.710022,892.962708 560.069397,894.835205 
	C544.970581,896.907776 529.714050,898.140503 514.484680,898.863037 
	C500.651337,899.519226 486.697754,899.862488 472.910156,898.831909 
	C435.794250,896.057617 399.518280,888.586853 364.567810,875.699585 
	C328.758789,862.495605 295.419403,844.511169 264.472076,822.059937 
	C242.431381,806.070251 223.145676,787.109741 204.273483,767.665771 
	C201.089752,764.385681 198.433319,760.593689 195.275452,756.783875 
M864.082275,443.251129 
	C864.044250,441.919739 864.246521,440.528473 863.931824,439.266052 
	C859.707764,422.319916 855.981079,405.222168 851.002808,388.497925 
	C843.069580,361.846741 831.216492,336.869934 817.060425,312.915283 
	C802.410278,288.124664 785.324341,265.137665 765.002686,244.878159 
	C751.042480,230.960449 735.717285,218.313004 720.291138,205.987656 
	C696.649231,187.098007 669.989746,173.016495 642.265503,161.263916 
	C617.491577,150.762024 591.782166,142.764542 564.997681,138.968933 
	C549.312195,136.746155 533.609802,133.991104 517.830688,133.234283 
	C494.912201,132.135071 472.014984,133.369232 449.192810,136.808960 
	C429.557831,139.768265 410.337219,144.057053 391.400085,149.890030 
	C363.081024,158.612793 336.298889,170.723328 310.925781,186.002899 
	C287.560425,200.073441 265.618988,216.189514 246.412415,235.470337 
	C232.029480,249.908890 218.700500,265.546967 206.138672,281.617432 
	C183.179520,310.989227 166.382080,343.884399 153.981201,379.009979 
	C147.284317,397.978912 141.638199,417.254517 138.933136,437.245331 
	C136.764389,453.272644 134.042953,469.302887 133.222046,485.416168 
	C132.071167,508.006683 133.487106,530.568787 136.835876,553.050171 
	C139.737091,572.527039 143.976120,591.614502 149.776382,610.383423 
	C157.792023,636.320984 168.604980,661.048218 182.162491,684.578918 
	C197.345673,710.931030 215.179626,735.413025 236.694687,756.834167 
	C250.664764,770.743408 265.865845,783.558167 281.421661,795.702393 
	C310.938873,818.746033 343.930603,835.654114 379.242249,848.063965 
	C399.930786,855.334778 420.962585,861.275146 442.771118,864.000793 
	C457.704742,865.867188 472.652130,867.963196 487.657928,868.802246 
	C499.363068,869.456787 511.183807,868.609253 522.922974,867.916992 
	C532.894287,867.329102 542.835388,866.122192 552.767090,864.989685 
	C573.466370,862.629272 593.562683,857.551208 613.377136,851.251892 
	C638.159058,843.373474 661.771606,832.842041 684.343811,819.959106 
	C710.316162,805.135620 734.387451,787.561218 755.553711,766.544861 
	C770.009888,752.190918 783.275818,736.480408 795.835938,720.408875 
	C818.806274,691.016968 835.562805,658.067505 848.010864,622.949890 
	C856.159729,599.960876 862.446045,576.488037 865.029541,552.149902 
	C866.412659,539.120361 868.246460,526.103577 868.827576,513.034424 
	C869.326538,501.811096 868.647583,490.500977 867.915344,479.266663 
	C867.147888,467.492981 865.696472,455.763885 864.082275,443.251129 
z"
                />
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M864.313110,443.633209 
	C865.696472,455.763885 867.147888,467.492981 867.915344,479.266663 
	C868.647583,490.500977 869.326538,501.811096 868.827576,513.034424 
	C868.246460,526.103577 866.412659,539.120361 865.029541,552.149902 
	C862.446045,576.488037 856.159729,599.960876 848.010864,622.949890 
	C835.562805,658.067505 818.806274,691.016968 795.835938,720.408875 
	C783.275818,736.480408 770.009888,752.190918 755.553711,766.544861 
	C734.387451,787.561218 710.316162,805.135620 684.343811,819.959106 
	C661.771606,832.842041 638.159058,843.373474 613.377136,851.251892 
	C593.562683,857.551208 573.466370,862.629272 552.767090,864.989685 
	C542.835388,866.122192 532.894287,867.329102 522.922974,867.916992 
	C511.183807,868.609253 499.363068,869.456787 487.657928,868.802246 
	C472.652130,867.963196 457.704742,865.867188 442.771118,864.000793 
	C420.962585,861.275146 399.930786,855.334778 379.242249,848.063965 
	C343.930603,835.654114 310.938873,818.746033 281.421661,795.702393 
	C265.865845,783.558167 250.664764,770.743408 236.694687,756.834167 
	C215.179626,735.413025 197.345673,710.931030 182.162491,684.578918 
	C168.604980,661.048218 157.792023,636.320984 149.776382,610.383423 
	C143.976120,591.614502 139.737091,572.527039 136.835876,553.050171 
	C133.487106,530.568787 132.071167,508.006683 133.222046,485.416168 
	C134.042953,469.302887 136.764389,453.272644 138.933136,437.245331 
	C141.638199,417.254517 147.284317,397.978912 153.981201,379.009979 
	C166.382080,343.884399 183.179520,310.989227 206.138672,281.617432 
	C218.700500,265.546967 232.029480,249.908890 246.412415,235.470337 
	C265.618988,216.189514 287.560425,200.073441 310.925781,186.002899 
	C336.298889,170.723328 363.081024,158.612793 391.400085,149.890030 
	C410.337219,144.057053 429.557831,139.768265 449.192810,136.808960 
	C472.014984,133.369232 494.912201,132.135071 517.830688,133.234283 
	C533.609802,133.991104 549.312195,136.746155 564.997681,138.968933 
	C591.782166,142.764542 617.491577,150.762024 642.265503,161.263916 
	C669.989746,173.016495 696.649231,187.098007 720.291138,205.987656 
	C735.717285,218.313004 751.042480,230.960449 765.002686,244.878159 
	C785.324341,265.137665 802.410278,288.124664 817.060425,312.915283 
	C831.216492,336.869934 843.069580,361.846741 851.002808,388.497925 
	C855.981079,405.222168 859.707764,422.319916 863.931824,439.266052 
	C864.246521,440.528473 864.044250,441.919739 864.313110,443.633209 
M493.487823,710.001038 
	C489.013123,709.188354 484.463898,708.660400 480.077026,707.512207 
	C463.753510,703.239685 448.109619,697.351990 433.234741,689.162109 
	C416.539734,679.970032 401.381104,668.821533 387.488617,655.917419 
	C369.699646,639.393982 354.134064,620.898193 340.196320,601.097656 
	C343.631042,591.069763 348.471130,581.331177 347.997040,570.232605 
	C347.718231,563.705750 344.295502,559.052917 338.656647,556.345703 
	C332.576355,553.426636 326.593903,555.043030 321.637634,559.801025 
	C317.261566,564.002075 318.207855,569.670227 316.759918,574.732788 
	C311.657532,592.572815 302.226837,607.986206 288.244446,620.419373 
	C283.017151,625.067566 280.264313,634.097900 286.208832,642.526611 
	C289.921875,647.791382 300.272003,649.909973 305.571503,645.717102 
	C311.728577,640.845764 317.189178,635.094177 322.950714,629.725952 
	C339.057922,650.940918 356.942139,671.473633 378.276520,688.807678 
	C397.287048,704.253784 417.597382,717.453491 440.354034,726.838928 
	C463.601776,736.426941 487.819336,741.673645 512.787476,742.788879 
	C525.088928,743.338501 537.507141,741.901245 549.840942,740.952820 
	C568.550659,739.514099 586.611145,734.854370 604.438416,729.194641 
	C625.493347,722.510071 645.938904,714.266907 665.092285,703.283997 
	C686.185181,691.188782 706.949890,678.502625 727.584534,665.636047 
	C735.616943,660.627441 736.449707,651.120605 731.670227,643.356445 
	C727.701294,636.909119 717.245850,635.899170 709.940552,640.453430 
	C690.771179,652.403809 671.877869,664.883362 652.111877,675.766724 
	C630.839600,687.479492 608.381226,696.764404 584.791748,703.115234 
	C566.292786,708.095459 547.687195,712.041748 528.422424,712.002319 
	C522.091797,711.989380 515.747742,712.253235 509.434692,711.919678 
	C504.398651,711.653625 499.400391,710.672546 493.487823,710.001038 
M693.940125,431.968872 
	C698.182739,430.306152 702.531982,428.785645 704.045288,423.753510 
	C707.578003,412.006378 712.042786,400.543213 712.141418,387.927704 
	C712.195251,381.046539 707.455017,374.359283 698.280396,373.044373 
	C691.675598,372.097778 683.592163,378.268616 682.016174,385.075958 
	C680.123108,393.252747 677.956299,401.370667 676.265869,409.587708 
	C675.563843,412.999939 675.124023,416.721832 675.729492,420.093842 
	C676.970154,427.003632 683.264954,433.269501 693.940125,431.968872 
M559.033997,422.028168 
	C564.379089,433.174164 575.098511,434.760925 582.101501,429.642487 
	C585.823608,426.922028 588.090271,421.764771 590.148560,417.307831 
	C594.219788,408.492035 598.741394,399.734924 598.048767,389.469574 
	C597.644592,383.479614 592.307617,376.971863 586.484619,376.015930 
	C578.222717,374.659576 571.393738,378.124542 568.978943,384.946594 
	C566.292480,392.536316 563.366028,400.057770 561.093689,407.770874 
	C559.821838,412.088226 559.663879,416.733734 559.033997,422.028168 
z"
                />
                <path
                  fill="#FFC900"
                  opacity="1.000000"
                  stroke="none"
                  d="
M493.936584,710.004883 
	C499.400391,710.672546 504.398651,711.653625 509.434692,711.919678 
	C515.747742,712.253235 522.091797,711.989380 528.422424,712.002319 
	C547.687195,712.041748 566.292786,708.095459 584.791748,703.115234 
	C608.381226,696.764404 630.839600,687.479492 652.111877,675.766724 
	C671.877869,664.883362 690.771179,652.403809 709.940552,640.453430 
	C717.245850,635.899170 727.701294,636.909119 731.670227,643.356445 
	C736.449707,651.120605 735.616943,660.627441 727.584534,665.636047 
	C706.949890,678.502625 686.185181,691.188782 665.092285,703.283997 
	C645.938904,714.266907 625.493347,722.510071 604.438416,729.194641 
	C586.611145,734.854370 568.550659,739.514099 549.840942,740.952820 
	C537.507141,741.901245 525.088928,743.338501 512.787476,742.788879 
	C487.819336,741.673645 463.601776,736.426941 440.354034,726.838928 
	C417.597382,717.453491 397.287048,704.253784 378.276520,688.807678 
	C356.942139,671.473633 339.057922,650.940918 322.950714,629.725952 
	C317.189178,635.094177 311.728577,640.845764 305.571503,645.717102 
	C300.272003,649.909973 289.921875,647.791382 286.208832,642.526611 
	C280.264313,634.097900 283.017151,625.067566 288.244446,620.419373 
	C302.226837,607.986206 311.657532,592.572815 316.759918,574.732788 
	C318.207855,569.670227 317.261566,564.002075 321.637634,559.801025 
	C326.593903,555.043030 332.576355,553.426636 338.656647,556.345703 
	C344.295502,559.052917 347.718231,563.705750 347.997040,570.232605 
	C348.471130,581.331177 343.631042,591.069763 340.196320,601.097656 
	C354.134064,620.898193 369.699646,639.393982 387.488617,655.917419 
	C401.381104,668.821533 416.539734,679.970032 433.234741,689.162109 
	C448.109619,697.351990 463.753510,703.239685 480.077026,707.512207 
	C484.463898,708.660400 489.013123,709.188354 493.936584,710.004883 
z"
                />
                <path
                  fill="#FFC900"
                  opacity="1.000000"
                  stroke="none"
                  d="
M693.512573,431.982574 
	C683.264954,433.269501 676.970154,427.003632 675.729492,420.093842 
	C675.124023,416.721832 675.563843,412.999939 676.265869,409.587708 
	C677.956299,401.370667 680.123108,393.252747 682.016174,385.075958 
	C683.592163,378.268616 691.675598,372.097778 698.280396,373.044373 
	C707.455017,374.359283 712.195251,381.046539 712.141418,387.927704 
	C712.042786,400.543213 707.578003,412.006378 704.045288,423.753510 
	C702.531982,428.785645 698.182739,430.306152 693.512573,431.982574 
z"
                />
                <path
                  fill="#FFC800"
                  opacity="1.000000"
                  stroke="none"
                  d="
M559.018982,421.629761 
	C559.663879,416.733734 559.821838,412.088226 561.093689,407.770874 
	C563.366028,400.057770 566.292480,392.536316 568.978943,384.946594 
	C571.393738,378.124542 578.222717,374.659576 586.484619,376.015930 
	C592.307617,376.971863 597.644592,383.479614 598.048767,389.469574 
	C598.741394,399.734924 594.219788,408.492035 590.148560,417.307831 
	C588.090271,421.764771 585.823608,426.922028 582.101501,429.642487 
	C575.098511,434.760925 564.379089,433.174164 559.018982,421.629761 
z"
                />
              </Svg>
            </ScoreBox>
            <ScoreBox
              onPanEnd={(e, info) => {
                console.log(item.id);
                DragandDrop(info.point.x, info.point.y, item.id, 3, 7.5);
              }}
              style={{
                visibility: isDropped[item.id - 1]?.scorenum[2]?.dropped
                  ? "hidden"
                  : "block",
              }}
              variants={ScoreBoxVariants}
              drag
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              animate="end"
              dragSnapToOrigin={true}
            >
              <Svg
                version="1.1"
                xmlns="http://www.w3.org/2000/Svg"
                x="0px"
                y="0px"
                width="100%"
                viewBox="0 0 1016 1000"
              >
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M1017.000000,376.000000 
   C1017.000000,584.666687 1017.000000,792.833313 1017.000000,1001.000000 
   C678.333313,1001.000000 339.666656,1001.000000 1.000000,1001.000000 
   C1.000000,667.666687 1.000000,334.333344 1.000000,1.000000 
   C339.666656,1.000000 678.333313,1.000000 1017.000000,1.000000 
   C1017.000000,125.833336 1017.000000,250.666672 1017.000000,376.000000 
M132.758148,756.771362 
   C169.326996,815.106140 216.447250,863.187134 274.137146,900.654114 
   C334.828827,940.070496 401.178772,964.365845 473.115570,973.324646 
   C507.663208,977.627075 542.230713,978.119568 576.805115,974.601746 
   C613.021057,970.916870 648.353333,963.010864 682.825745,951.158020 
   C745.788574,929.509094 801.641357,896.005066 850.264832,850.635986 
   C895.357056,808.561768 930.611267,759.263611 956.377930,703.206177 
   C981.281555,649.026367 995.310303,592.091003 998.543457,532.624939 
   C1000.538696,495.927856 998.068848,459.429352 991.287720,423.207031 
   C984.020813,384.390106 972.294739,347.041138 955.752808,311.233673 
   C925.939392,246.698471 883.638550,191.682098 828.751343,146.484650 
   C766.987305,95.624352 696.654541,62.652607 618.184814,47.364433 
   C571.960571,38.358612 525.345703,36.568424 478.465332,41.941292 
   C424.884369,48.082100 373.823334,62.771397 325.312653,86.281731 
   C267.914642,114.099258 218.293961,152.109741 176.456055,200.271530 
   C132.473175,250.902603 100.778313,308.399658 81.345062,372.499237 
   C65.160355,425.883667 58.579250,480.523407 62.126945,536.321777 
   C64.472458,573.212158 70.956116,609.338379 81.734901,644.651733 
   C93.745010,683.998962 110.581963,721.216248 132.758148,756.771362 
z"
                />
                <path
                  fill="#FDC905"
                  opacity="1.000000"
                  stroke="none"
                  d="
M132.567200,756.461060 
   C110.581963,721.216248 93.745010,683.998962 81.734901,644.651733 
   C70.956116,609.338379 64.472458,573.212158 62.126945,536.321777 
   C58.579250,480.523407 65.160355,425.883667 81.345062,372.499237 
   C100.778313,308.399658 132.473175,250.902603 176.456055,200.271530 
   C218.293961,152.109741 267.914642,114.099258 325.312653,86.281731 
   C373.823334,62.771397 424.884369,48.082100 478.465332,41.941292 
   C525.345703,36.568424 571.960571,38.358612 618.184814,47.364433 
   C696.654541,62.652607 766.987305,95.624352 828.751343,146.484650 
   C883.638550,191.682098 925.939392,246.698471 955.752808,311.233673 
   C972.294739,347.041138 984.020813,384.390106 991.287720,423.207031 
   C998.068848,459.429352 1000.538696,495.927856 998.543457,532.624939 
   C995.310303,592.091003 981.281555,649.026367 956.377930,703.206177 
   C930.611267,759.263611 895.357056,808.561768 850.264832,850.635986 
   C801.641357,896.005066 745.788574,929.509094 682.825745,951.158020 
   C648.353333,963.010864 613.021057,970.916870 576.805115,974.601746 
   C542.230713,978.119568 507.663208,977.627075 473.115570,973.324646 
   C401.178772,964.365845 334.828827,940.070496 274.137146,900.654114 
   C216.447250,863.187134 169.326996,815.106140 132.567200,756.461060 
M332.552734,894.033752 
   C364.343567,910.476990 397.741516,922.636475 432.563263,930.720093 
   C481.858490,942.163574 531.748535,944.845337 581.967407,938.770691 
   C625.696716,933.481018 667.754883,921.759399 707.964172,903.667786 
   C768.169922,876.578918 819.642090,837.843140 862.115479,787.297791 
   C904.794312,736.507935 934.342957,678.852051 950.728638,614.602783 
   C963.694031,563.764893 967.202942,512.206726 961.336121,459.975372 
   C956.086792,413.241699 943.633057,368.568878 923.977478,325.931183 
   C897.435974,268.356415 860.002258,218.936127 811.679260,177.851105 
   C755.501831,130.088150 691.194092,98.651070 619.168274,83.292244 
   C574.559204,73.779770 529.484009,71.700981 484.035248,76.627701 
   C432.672150,82.195549 383.872253,96.371407 337.625732,119.193352 
   C284.993134,145.166763 239.479492,180.476349 201.260635,225.069748 
   C156.715897,277.044098 126.097771,336.326538 109.300484,402.616272 
   C96.830017,451.830414 93.565063,501.796692 98.697212,552.345520 
   C102.674461,591.519165 112.000839,629.354614 126.245140,666.003601 
   C144.829330,713.818604 171.115112,756.999939 205.141098,795.370178 
   C241.215881,836.050598 283.430298,868.922485 332.552734,894.033752 
z"
                />
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M332.227051,893.852905 
   C283.430298,868.922485 241.215881,836.050598 205.141098,795.370178 
   C171.115112,756.999939 144.829330,713.818604 126.245140,666.003601 
   C112.000839,629.354614 102.674461,591.519165 98.697212,552.345520 
   C93.565063,501.796692 96.830017,451.830414 109.300484,402.616272 
   C126.097771,336.326538 156.715897,277.044098 201.260635,225.069748 
   C239.479492,180.476349 284.993134,145.166763 337.625732,119.193352 
   C383.872253,96.371407 432.672150,82.195549 484.035248,76.627701 
   C529.484009,71.700981 574.559204,73.779770 619.168274,83.292244 
   C691.194092,98.651070 755.501831,130.088150 811.679260,177.851105 
   C860.002258,218.936127 897.435974,268.356415 923.977478,325.931183 
   C943.633057,368.568878 956.086792,413.241699 961.336121,459.975372 
   C967.202942,512.206726 963.694031,563.764893 950.728638,614.602783 
   C934.342957,678.852051 904.794312,736.507935 862.115479,787.297791 
   C819.642090,837.843140 768.169922,876.578918 707.964172,903.667786 
   C667.754883,921.759399 625.696716,933.481018 581.967407,938.770691 
   C531.748535,944.845337 481.858490,942.163574 432.563263,930.720093 
   C397.741516,922.636475 364.343567,910.476990 332.227051,893.852905 
M489.907593,686.865906 
   C476.724670,684.990723 463.581421,682.733032 450.346405,681.360107 
   C439.556885,680.240906 428.837646,674.360474 417.519226,681.125061 
   C417.308563,678.828918 417.310791,677.361755 417.022797,675.953979 
   C415.031616,666.220947 413.678162,656.284363 410.754883,646.835449 
   C407.725281,637.042908 396.121521,634.127136 388.305359,640.303711 
   C382.980957,644.511230 381.357025,650.700745 383.929352,657.874451 
   C388.687408,671.143738 389.801453,684.665955 385.154694,698.021240 
   C382.172302,706.592957 377.962982,714.775879 373.858002,722.908142 
   C369.849854,730.848633 371.507904,739.062744 378.607391,743.414673 
   C385.625244,747.716614 394.275208,745.613281 398.843994,737.923889 
   C402.657349,731.505920 406.075287,724.812988 409.144196,718.005493 
   C411.608948,712.538208 413.288452,706.716858 415.101440,701.653076 
   C419.153503,703.570312 422.524384,706.081238 426.236572,706.780640 
   C465.892822,714.252625 505.911255,718.900574 546.225586,720.532166 
   C594.279724,722.476929 642.158752,720.031494 689.689331,712.566528 
   C736.178040,705.265259 781.584167,693.597351 825.555237,676.733582 
   C829.196411,675.337219 832.805786,671.821960 834.688232,668.347473 
   C837.598145,662.976746 836.303772,657.222900 831.869629,652.731201 
   C826.959290,647.757141 821.101379,647.396423 814.784302,649.737915 
   C783.367188,661.382996 751.274780,670.604736 718.461670,677.500549 
   C672.641724,687.129883 626.312500,692.112610 579.580627,691.648560 
   C549.978455,691.354553 520.400696,688.599487 489.907593,686.865906 
M764.120178,422.711975 
   C766.217896,419.580353 769.214417,416.717438 770.245850,413.267059 
   C773.433289,402.604553 776.173279,391.783051 778.517212,380.900574 
   C780.924805,369.721893 774.719360,360.452576 764.160217,358.431244 
   C754.056274,356.497009 746.031982,362.775391 743.662109,374.019196 
   C741.539856,384.088470 738.993591,394.068054 736.660095,404.093170 
   C734.857239,411.838593 736.832886,418.389221 743.427490,423.002289 
   C749.966736,427.576630 756.783325,427.469818 764.120178,422.711975 
M639.220459,398.781189 
   C640.846985,392.209076 643.292297,385.712036 643.903442,379.046814 
   C644.766235,369.636780 638.361023,362.436646 629.507446,361.161407 
   C620.303650,359.835754 612.910034,364.640625 609.912842,373.523346 
   C606.689575,383.076324 603.283813,392.571014 599.778015,402.024475 
   C596.397888,411.138916 600.095825,421.066345 608.483765,424.799316 
   C617.582886,428.848724 627.083008,425.522095 631.591736,416.388580 
   C634.306580,410.889191 636.530273,405.147308 639.220459,398.781189 
z"
                />
                <path
                  fill="#FDC907"
                  opacity="1.000000"
                  stroke="none"
                  d="
M490.359802,686.907043 
   C520.400696,688.599487 549.978455,691.354553 579.580627,691.648560 
   C626.312500,692.112610 672.641724,687.129883 718.461670,677.500549 
   C751.274780,670.604736 783.367188,661.382996 814.784302,649.737915 
   C821.101379,647.396423 826.959290,647.757141 831.869629,652.731201 
   C836.303772,657.222900 837.598145,662.976746 834.688232,668.347473 
   C832.805786,671.821960 829.196411,675.337219 825.555237,676.733582 
   C781.584167,693.597351 736.178040,705.265259 689.689331,712.566528 
   C642.158752,720.031494 594.279724,722.476929 546.225586,720.532166 
   C505.911255,718.900574 465.892822,714.252625 426.236572,706.780640 
   C422.524384,706.081238 419.153503,703.570312 415.101440,701.653076 
   C413.288452,706.716858 411.608948,712.538208 409.144196,718.005493 
   C406.075287,724.812988 402.657349,731.505920 398.843994,737.923889 
   C394.275208,745.613281 385.625244,747.716614 378.607391,743.414673 
   C371.507904,739.062744 369.849854,730.848633 373.858002,722.908142 
   C377.962982,714.775879 382.172302,706.592957 385.154694,698.021240 
   C389.801453,684.665955 388.687408,671.143738 383.929352,657.874451 
   C381.357025,650.700745 382.980957,644.511230 388.305359,640.303711 
   C396.121521,634.127136 407.725281,637.042908 410.754883,646.835449 
   C413.678162,656.284363 415.031616,666.220947 417.022797,675.953979 
   C417.310791,677.361755 417.308563,678.828918 417.519226,681.125061 
   C428.837646,674.360474 439.556885,680.240906 450.346405,681.360107 
   C463.581421,682.733032 476.724670,684.990723 490.359802,686.907043 
z"
                />
                <path
                  fill="#FDC908"
                  opacity="1.000000"
                  stroke="none"
                  d="
M763.806519,422.903748 
   C756.783325,427.469818 749.966736,427.576630 743.427490,423.002289 
   C736.832886,418.389221 734.857239,411.838593 736.660095,404.093170 
   C738.993591,394.068054 741.539856,384.088470 743.662109,374.019196 
   C746.031982,362.775391 754.056274,356.497009 764.160217,358.431244 
   C774.719360,360.452576 780.924805,369.721893 778.517212,380.900574 
   C776.173279,391.783051 773.433289,402.604553 770.245850,413.267059 
   C769.214417,416.717438 766.217896,419.580353 763.806519,422.903748 
z"
                />
                <path
                  fill="#FCC907"
                  opacity="1.000000"
                  stroke="none"
                  d="
M639.097534,399.147797 
   C636.530273,405.147308 634.306580,410.889191 631.591736,416.388580 
   C627.083008,425.522095 617.582886,428.848724 608.483765,424.799316 
   C600.095825,421.066345 596.397888,411.138916 599.778015,402.024475 
   C603.283813,392.571014 606.689575,383.076324 609.912842,373.523346 
   C612.910034,364.640625 620.303650,359.835754 629.507446,361.161407 
   C638.361023,362.436646 644.766235,369.636780 643.903442,379.046814 
   C643.292297,385.712036 640.846985,392.209076 639.097534,399.147797 
z"
                />
              </Svg>
            </ScoreBox>
            <ScoreBox
              onPanEnd={(e, info) => {
                console.log(item.id);
                DragandDrop(info.point.x, info.point.y, item.id, 4, 10);
              }}
              style={{
                visibility: isDropped[item.id - 1]?.scorenum[3]?.dropped
                  ? "hidden"
                  : "block",
              }}
              variants={ScoreBoxVariants}
              drag
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              animate="end"
              dragSnapToOrigin={true}
            >
              <Svg
                version="1.1"
                xmlns="http://www.w3.org/2000/Svg"
                x="0px"
                y="0px"
                width="100%"
                viewBox="0 0 1065 1000"
              >
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M1066.000000,379.000000 
   C1066.000000,586.641602 1066.000000,793.783264 1066.000000,1000.962402 
   C711.067993,1000.962402 356.135986,1000.962402 1.101981,1000.962402 
   C1.101981,667.775024 1.101981,334.549957 1.101981,1.162450 
   C355.999939,1.162450 710.999939,1.162450 1066.000000,1.162450 
   C1066.000000,126.864159 1066.000000,252.682083 1066.000000,379.000000 
M77.097603,582.519653 
   C82.372803,601.242371 86.783501,620.261414 93.081467,638.633545 
   C113.962273,699.546082 147.105438,753.151917 191.683380,799.598206 
   C229.916351,839.433716 274.132080,871.021973 324.123535,894.499939 
   C378.955811,920.251404 436.694916,934.312073 497.170898,936.617432 
   C549.234619,938.602112 600.118958,931.350220 649.742859,915.316467 
   C730.705505,889.156921 799.060303,843.916260 853.821899,778.868835 
   C913.272217,708.252075 947.719238,626.727722 957.190369,534.776001 
   C961.783142,490.186584 959.503357,445.916168 950.496643,402.058624 
   C941.603943,358.756226 926.780273,317.526672 905.641479,278.633942 
   C866.202820,206.071762 810.902100,148.728531 739.817810,106.789696 
   C682.160706,72.772659 619.894897,52.774750 553.142212,46.706730 
   C518.444458,43.552612 483.879272,44.453053 449.473083,49.492580 
   C413.098206,54.820457 377.881500,64.544052 343.840454,78.594032 
   C269.625793,109.225075 207.957321,156.273438 159.324951,220.071457 
   C110.100441,284.646271 80.611328,357.404846 70.713158,438.092804 
   C64.795082,486.335785 67.086548,534.159851 77.097603,582.519653 
z"
                />
                <path
                  fill="#FDC905"
                  opacity="1.000000"
                  stroke="none"
                  d="
M77.043350,582.100464 
   C67.086548,534.159851 64.795082,486.335785 70.713158,438.092804 
   C80.611328,357.404846 110.100441,284.646271 159.324951,220.071457 
   C207.957321,156.273438 269.625793,109.225075 343.840454,78.594032 
   C377.881500,64.544052 413.098206,54.820457 449.473083,49.492580 
   C483.879272,44.453053 518.444458,43.552612 553.142212,46.706730 
   C619.894897,52.774750 682.160706,72.772659 739.817810,106.789696 
   C810.902100,148.728531 866.202820,206.071762 905.641479,278.633942 
   C926.780273,317.526672 941.603943,358.756226 950.496643,402.058624 
   C959.503357,445.916168 961.783142,490.186584 957.190369,534.776001 
   C947.719238,626.727722 913.272217,708.252075 853.821899,778.868835 
   C799.060303,843.916260 730.705505,889.156921 649.742859,915.316467 
   C600.118958,931.350220 549.234619,938.602112 497.170898,936.617432 
   C436.694916,934.312073 378.955811,920.251404 324.123535,894.499939 
   C274.132080,871.021973 229.916351,839.433716 191.683380,799.598206 
   C147.105438,753.151917 113.962273,699.546082 93.081467,638.633545 
   C86.783501,620.261414 82.372803,601.242371 77.043350,582.100464 
M906.725403,365.939850 
   C898.790100,346.377258 892.024414,326.238129 882.714905,307.352966 
   C844.531555,229.894455 787.002441,170.624924 711.405884,128.996033 
   C663.134216,102.414146 611.509033,86.390099 556.648743,80.581718 
   C525.339355,77.266823 494.078522,77.450760 462.918701,81.297516 
   C426.411835,85.804359 391.063507,95.115257 356.998016,109.162834 
   C282.176331,140.017044 221.532089,188.636047 175.113861,254.839096 
   C146.943359,295.016693 126.832657,339.018677 114.336891,386.527863 
   C106.808586,415.150574 102.322739,444.222717 101.151779,473.737366 
   C99.219826,522.432983 105.716347,570.015747 120.614761,616.507751 
   C139.153610,674.360168 168.781906,725.743286 209.908554,770.349060 
   C255.076065,819.337280 309.165649,855.172913 371.705109,878.261841 
   C409.785370,892.320618 449.109863,900.602356 489.576569,902.834106 
   C533.946411,905.280945 577.696411,900.942261 620.700989,889.224121 
   C662.974792,877.705139 702.552307,860.254883 739.187561,836.303162 
   C791.460327,802.127808 833.820984,758.259888 866.162964,704.802063 
   C904.047913,642.182190 923.941772,574.212158 925.989075,501.098755 
   C927.272705,455.261292 920.734619,410.448547 906.725403,365.939850 
z"
                />
                <path
                  fill="none"
                  opacity="1.000000"
                  stroke="none"
                  d="
M906.827576,366.324219 
   C920.734619,410.448547 927.272705,455.261292 925.989075,501.098755 
   C923.941772,574.212158 904.047913,642.182190 866.162964,704.802063 
   C833.820984,758.259888 791.460327,802.127808 739.187561,836.303162 
   C702.552307,860.254883 662.974792,877.705139 620.700989,889.224121 
   C577.696411,900.942261 533.946411,905.280945 489.576569,902.834106 
   C449.109863,900.602356 409.785370,892.320618 371.705109,878.261841 
   C309.165649,855.172913 255.076065,819.337280 209.908554,770.349060 
   C168.781906,725.743286 139.153610,674.360168 120.614761,616.507751 
   C105.716347,570.015747 99.219826,522.432983 101.151779,473.737366 
   C102.322739,444.222717 106.808586,415.150574 114.336891,386.527863 
   C126.832657,339.018677 146.943359,295.016693 175.113861,254.839096 
   C221.532089,188.636047 282.176331,140.017044 356.998016,109.162834 
   C391.063507,95.115257 426.411835,85.804359 462.918701,81.297516 
   C494.078522,77.450760 525.339355,77.266823 556.648743,80.581718 
   C611.509033,86.390099 663.134216,102.414146 711.405884,128.996033 
   C787.002441,170.624924 844.531555,229.894455 882.714905,307.352966 
   C892.024414,326.238129 898.790100,346.377258 906.827576,366.324219 
M514.682983,662.137085 
   C521.951965,657.758728 529.055542,653.073853 536.516296,649.051147 
   C590.520569,619.932800 648.525024,611.981567 708.838318,617.314392 
   C741.431702,620.196289 773.307007,627.046021 804.723816,636.200073 
   C808.381042,637.265747 813.104675,636.768433 816.719849,635.387756 
   C822.087952,633.337708 824.682495,627.410034 823.906738,621.802979 
   C822.902283,614.543457 818.069336,611.043335 811.433350,609.190308 
   C786.249207,602.157959 760.856384,596.147827 734.931519,592.468262 
   C701.628418,587.741333 668.274719,586.556030 634.793701,590.242981 
   C605.145935,593.507751 576.494934,600.670837 548.993103,612.246216 
   C496.643433,634.279968 454.357819,669.294250 419.119141,713.318176 
   C413.423370,720.433960 408.023590,727.786621 402.967896,734.400635 
   C395.238281,731.691345 388.170349,728.813843 380.866302,726.787476 
   C375.539825,725.309692 370.989136,727.831299 367.840759,732.204590 
   C364.698517,736.569153 364.233612,741.291260 366.669159,746.350403 
   C369.206482,751.620911 373.986511,753.232239 378.953308,754.868652 
   C391.567963,759.024902 398.376801,767.884094 399.124786,781.082642 
   C399.322510,784.571411 399.160522,788.078918 399.257782,791.575195 
   C399.486023,799.779236 405.209930,805.744751 412.932983,805.898193 
   C420.895142,806.056396 426.695557,800.160278 427.060822,791.635132 
   C427.521362,780.885376 426.500946,770.385559 422.792603,760.164246 
   C422.145996,758.382141 422.537109,755.462463 423.643066,753.929688 
   C430.555878,744.348694 437.508331,734.766357 445.005127,725.642578 
   C464.992035,701.318115 487.652924,679.848450 514.682983,662.137085 
M717.072693,360.812592 
   C714.596313,371.308167 712.088623,381.796448 709.653259,392.301514 
   C707.556824,401.344238 712.744873,410.368469 721.284363,412.671753 
   C729.745667,414.953888 738.912415,410.471527 741.334351,402.189240 
   C744.406982,391.681915 747.241394,381.068359 749.367798,370.339935 
   C750.157715,366.354553 749.443787,361.626404 748.023438,357.746674 
   C745.590515,351.101257 739.740234,348.350647 732.936035,348.391815 
   C725.060303,348.439392 719.970886,352.790710 717.072693,360.812592 
M620.966003,375.438934 
   C621.265442,372.464447 621.910767,369.476776 621.798096,366.518036 
   C621.488770,358.397064 615.491028,352.191345 607.372864,351.187805 
   C599.308899,350.190948 591.926147,354.873016 589.382507,362.360107 
   C586.185913,371.769073 582.890503,381.146027 579.506897,390.489410 
   C576.420105,399.012970 580.084534,408.201965 588.209106,411.884430 
   C596.335266,415.567596 605.962524,412.406647 609.760864,404.034729 
   C613.873840,394.969269 617.184875,385.539978 620.966003,375.438934 
z"
                />
                <path
                  fill="#FDC907"
                  opacity="1.000000"
                  stroke="none"
                  d="
M514.386230,662.343811 
   C487.652924,679.848450 464.992035,701.318115 445.005127,725.642578 
   C437.508331,734.766357 430.555878,744.348694 423.643066,753.929688 
   C422.537109,755.462463 422.145996,758.382141 422.792603,760.164246 
   C426.500946,770.385559 427.521362,780.885376 427.060822,791.635132 
   C426.695557,800.160278 420.895142,806.056396 412.932983,805.898193 
   C405.209930,805.744751 399.486023,799.779236 399.257782,791.575195 
   C399.160522,788.078918 399.322510,784.571411 399.124786,781.082642 
   C398.376801,767.884094 391.567963,759.024902 378.953308,754.868652 
   C373.986511,753.232239 369.206482,751.620911 366.669159,746.350403 
   C364.233612,741.291260 364.698517,736.569153 367.840759,732.204590 
   C370.989136,727.831299 375.539825,725.309692 380.866302,726.787476 
   C388.170349,728.813843 395.238281,731.691345 402.967896,734.400635 
   C408.023590,727.786621 413.423370,720.433960 419.119141,713.318176 
   C454.357819,669.294250 496.643433,634.279968 548.993103,612.246216 
   C576.494934,600.670837 605.145935,593.507751 634.793701,590.242981 
   C668.274719,586.556030 701.628418,587.741333 734.931519,592.468262 
   C760.856384,596.147827 786.249207,602.157959 811.433350,609.190308 
   C818.069336,611.043335 822.902283,614.543457 823.906738,621.802979 
   C824.682495,627.410034 822.087952,633.337708 816.719849,635.387756 
   C813.104675,636.768433 808.381042,637.265747 804.723816,636.200073 
   C773.307007,627.046021 741.431702,620.196289 708.838318,617.314392 
   C648.525024,611.981567 590.520569,619.932800 536.516296,649.051147 
   C529.055542,653.073853 521.951965,657.758728 514.386230,662.343811 
z"
                />
                <path
                  fill="#FDC908"
                  opacity="1.000000"
                  stroke="none"
                  d="
M717.146729,360.423065 
   C719.970886,352.790710 725.060303,348.439392 732.936035,348.391815 
   C739.740234,348.350647 745.590515,351.101257 748.023438,357.746674 
   C749.443787,361.626404 750.157715,366.354553 749.367798,370.339935 
   C747.241394,381.068359 744.406982,391.681915 741.334351,402.189240 
   C738.912415,410.471527 729.745667,414.953888 721.284363,412.671753 
   C712.744873,410.368469 707.556824,401.344238 709.653259,392.301514 
   C712.088623,381.796448 714.596313,371.308167 717.146729,360.423065 
z"
                />
                <path
                  fill="#FCC908"
                  opacity="1.000000"
                  stroke="none"
                  d="
M620.905823,375.854126 
   C617.184875,385.539978 613.873840,394.969269 609.760864,404.034729 
   C605.962524,412.406647 596.335266,415.567596 588.209106,411.884430 
   C580.084534,408.201965 576.420105,399.012970 579.506897,390.489410 
   C582.890503,381.146027 586.185913,371.769073 589.382507,362.360107 
   C591.926147,354.873016 599.308899,350.190948 607.372864,351.187805 
   C615.491028,352.191345 621.488770,358.397064 621.798096,366.518036 
   C621.910767,369.476776 621.265442,372.464447 620.905823,375.854126 
z"
                />
              </Svg>
            </ScoreBox>
          </ScoreGrid>
        </Container>
      ))}
      <motion.div id="progressY" style={{ scaleX }}></motion.div>
      <Footer>
        <img
          style={{ position: "absolute" }}
          src="img/뿡뿡심금.png"
          height="55rem"
          onClick={() => {
            nextPage();
          }}
        />
      </Footer>
    </Wrapper>
  );
}
export default Page1;
